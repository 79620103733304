



















import { Vue, Component } from "vue-property-decorator";
import Locations from "@/store/locations";
import FancyButton from "@/components/FancyButton.vue";

@Component({
  components: {
    FancyButton
  }
})
export default class extends Vue {
  headers = [
    { text: "Name", value: "name" },
    { text: "City", value: "city" },
    { text: "Zip code", value: "zipCode" },
    { text: "State", value: "state" },
  ];

  get dropSites() {
    return Locations.dropSites;
  }

  get loading() {
    return Locations.loading;
  }

  get error() {
    return Locations.error;
  }

  async mounted() {
    this.refresh();
  }

  async refresh() {
    await Locations.fetchAll();
  }

  reload() {
    this.refresh();
    this.$forceUpdate();
  }
}
